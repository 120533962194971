<template>
  <div class="infoStrip d-flex">
    <div class="totalresult_right flex-fill" style="text-align: justify;">
      <h3>{{ $t("search-result.leisure-packages-for") }}: {{destination}}</h3>
      <p>{{ $t("search-result.best-packages-rates") }}</p>
      <p>{{ $t("search-result.domestic-search.search-result-for")}}: {{date}}</p>
    </div>
    <div class="totalresults flex-fill">
      <p style="text-align: center;">{{ $t("search-result.total") }}  <br/><span style="font-weight:700;font-size:25px;">{{ count }}</span> <br/>{{ $t("search-result.result") }} </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InforStrip',
  components: {},
  props: {
    count: Number,
    destination: String,
    date: String,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.infoStrip {
  background-color: #0061ab;
  margin-bottom: 2px;
  display: none !important;
}

@media (max-width: 479px) {
  .infoStrip {
    display: flex !important;
  }
}
</style>
